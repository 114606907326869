// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/static/fonts/Objective-Bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../public/static/fonts/Objective-Light.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@font-face {
  font-family: "Objective-Bold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Objective-Extra-Light";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("opentype");
  font-weight: 400;
  font-style: normal;
}

.footer {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: black;
  color: white;
  padding: 20px 40px;
}


.footer-powered-by {
  color: #ffffff;
  margin-top: 0px;
  padding: 0px 0px;
  font-size: 16px;
  font-family: "Prompt", sans-serif;
}

@media (max-width: 768px) {
  footer.footer{
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .footer-powered-by{
    font-family: "Objective-Extra-Light";
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 20px;
    font-size: 15px;
  }

}
`, "",{"version":3,"sources":["webpack://./src/styles/Footer.css"],"names":[],"mappings":";AACA;EACE,6BAA6B;EAC7B,+DAA2E;EAC3E,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,oCAAoC;EACpC,+DAA4E;EAC5E,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;AACpB;;;AAGA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE;IACE,iBAAiB;IACjB,oBAAoB;EACtB;;EAEA;IACE,oCAAoC;IACpC,eAAe;IACf,kBAAkB;IAClB,oBAAoB;IACpB,eAAe;EACjB;;AAEF","sourcesContent":["\n@font-face {\n  font-family: \"Objective-Bold\";\n  src: url(\"../../public/static/fonts/Objective-Bold.otf\") format(\"opentype\");\n  font-weight: 700;\n  font-style: normal;\n}\n@font-face {\n  font-family: \"Objective-Extra-Light\";\n  src: url(\"../../public/static/fonts/Objective-Light.otf\") format(\"opentype\");\n  font-weight: 400;\n  font-style: normal;\n}\n\n.footer {\n  display: flex;\n  justify-content: center;\n  text-align: center;\n  align-items: center;\n  background-color: black;\n  color: white;\n  padding: 20px 40px;\n}\n\n\n.footer-powered-by {\n  color: #ffffff;\n  margin-top: 0px;\n  padding: 0px 0px;\n  font-size: 16px;\n  font-family: \"Prompt\", sans-serif;\n}\n\n@media (max-width: 768px) {\n  footer.footer{\n    padding-top: 40px;\n    padding-bottom: 40px;\n  }\n\n  .footer-powered-by{\n    font-family: \"Objective-Extra-Light\";\n    margin-top: 0px;\n    margin-bottom: 0px;\n    padding-bottom: 20px;\n    font-size: 15px;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
